<template>
  <div class="main-wrapper">
    <Header v-if="show"/>
    <Sidebar v-if="show"/>
    <router-view/>
    <div class="modal-backdrop fade hide" id="backdrop"></div>
  </div>
</template>

<script>
import Header from './components/Header.vue'
import Sidebar from './components/Sidebar.vue';
import axios from 'axios'
export default {
  components:{
    Header,
    Sidebar
  },
  data(){
    return{
      show: false
    }
  },
  watch: {
      "$route.params": {
          handler(newValue, preValue) {
            this.handleRoute()
          },
          immediate: true
      }
  },
  created(){
    if(localStorage.getItem('user') && this.$route.path != "/dashboard"){
       this.$store.dispatch("leaves")
        this.$store.dispatch("settings")
        this.$store.dispatch("applications")
        this.$store.dispatch("approvals")
    }
  },
  methods:{
    handleRoute(){
       
      if(this.$route.path == "/login" || this.$route.path == "/register" || this.$route.path == "/"){
        this.show = false
      }else{
        this.show = true
      }
    }
  }
}
</script>

