<template>
       <div class="header">

            <div class="header-left">
                <a href="/#/dashboard" class="logo">
                    <img src="@/assets/logo.png" width="40" height="40" alt="">
                </a>
            </div>

            <div class="header-center">
                <h1>JBnJAWS <sup>HRM</sup></h1>
            </div>
            

            <ul class="nav user-menu">
               
               
                <li class="nav-item dropdown">
                    <a  class="dropdown-toggle nav-link" data-bs-toggle="dropdown">
                        <i class="far fa-bell"></i> <span class="badge rounded-pill">3</span>
                    </a>
                </li>

                <li class="nav-item dropdown has-arrow main-drop">
                    <a v-if="!showM" @click="showM = true" class="dropdown-toggle nav-link">
                        <span>Admin</span>
                    </a>
                     <a  v-if="showM" @click="showM = false" class="dropdown-toggle nav-link" data-bs-toggle="dropup">
                        <span>Admin</span>
                    </a>
                    <div class="dropdown-menu " v-bind:class="{'show':showM}" style="top: 59px;">
                        <a class="dropdown-item">My Profile</a>
                        <a class="dropdown-item">Settings</a>
                        <a class="dropdown-item">Logout</a>
                    </div>
                </li>
            </ul>
            <a id="mobile_btn" class="mobile_btn" @click="toggleSideBar()"><i class="fa fa-bars"></i></a>

            <div class="dropdown mobile-user-menu">
                <a v-if="!showM" @click="showM = true" class="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i
                        class="fa fa-ellipsis-v"></i></a>
                         <a v-if="showM" @click="showM = false" class="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i
                        class="fa fa-ellipsis-v"></i></a>
                        
                <div v-bind:class="{'show':showM}" class="dropdown-menu dropdown-menu-right" style="left: -43px;">
                    <a class="dropdown-item">My Profile</a>
                    <a class="dropdown-item">Settings</a>
                    <a class="dropdown-item">Logout</a>
                </div>
            </div>

        </div>
</template>

<script>
import axios from 'axios'
export default {
  components:{
  },
  data(){
    return{
      showM:false
    }
  },
  created(){
    
  },
  methods:{
    toggleSideBar(){
        if(document.getElementById('sidebar').classList.contains('showSideBar')){
            document.getElementById('sidebar').classList.remove('showSideBar')
        }else{
            document.getElementById('sidebar').classList.add('showSideBar')
        }
        
    }
  }
};
</script>
