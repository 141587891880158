import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import moment from 'moment'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        api: "https://api-server-digital-madness.web.app/",
        api_base: "https://api-server-digital-madness.web.app/",
       // api:'http://localhost:5000/',
        event: 'jbnjaws',
        applications: { forgot_to_mark: 0, special_circumstance: 0, meeting: 0, forgot_to_mark_total: 0, special_circumstance_total: 0, meeting_total: 0, applications_list: [] },
        leaves: { sick: 0, annual: 0, casual: 0, sick_total: 0, annual_total: 0, casual_total: 0, leave_list: [] },
        approvals: { leaves: [], applications: [] }
    },
    mutations: {
        leaves(state, obj) {
            state.leaves = obj
        },
        leaves_availed(state, obj){
            state.leaves.sick = obj.sick
            state.leaves.casual = obj.casual
            state.leaves.annual = obj.annual
            state.leaves.leave_list = obj.leave_list
        },
        applications_availed(state, obj) {
            state.applications.forgot_to_mark = obj.forgot_to_mark
            state.applications.special_circumstance = obj.special_circumstance
            state.applications.meeting = obj.meeting
            state.applications.applications_list = obj.applications_list
        },
        applications(state, obj) {
            state.applications = obj
        },
        approvals_leaves(state, obj) {
            state.approvals.leaves = obj
        },
        approvals_applications(state, obj) {
            state.approvals.applications = obj
        }
    },
    modules: {},
    actions: {
        leaves({ commit, state }) {
            var user = JSON.parse(localStorage.getItem('user'));
            ////console.log(user)
            var y =  moment().format('YYYY')
            var ny =  moment().add('1','year').format('YYYY')
            axios
                .post(
                    state.api + "~/fs_get", {
                    table: "hr_leave",
                    query: [
                        {
                            "f":"from_date",
                            "o":">=",
                            "v":y
                        },
                        {
                            "f":"from_date",
                            "o":"<=",
                            "v":ny
                        },
                        {
                            "f":"employee_id",
                            "o":"==",
                            "v":user.machine_id
                        }
                    ]
                }
                ).then(response => {
                    console.log(response,'Leaved Data');
                    if (response.data.length) {
                        var s = response.data;
                        var t = []
                        for (var i in s) {
                            var f = s[i];
                            t.push(f)
                        }
                        var l = { sick: 0, annual: 0, casual: 0, leave_list: [] };
                        //console.log(t, '123');
                        for (var i in t) {
                            //console.log(t[i].status)
                            if (t[i].status.hr != 'cancelled' && t[i].status.line_manager != 'cancelled') {
                                //console.log(t[i].type)
                                if (t[i].type == 'Sick Leave') {
                                    l.sick = parseInt(l.sick) + parseInt(t[i].no_of_days)
                                } else if (t[i].type == 'Casual Leave') {
                                    l.casual = parseInt(l.casual) + parseInt(t[i].no_of_days)
                                } else if (t[i].type == 'Annual Leave') {
                                    l.annual = parseInt(l.annual) + parseInt(t[i].no_of_days)
                                }
                            }
                        }
                        //console.log(l)
                        commit("leaves_availed", l);
                    } else {
                        var l = state.leaves;
                        commit("leaves_availed", l);
                    }
                    //commit("favorite");
                }).catch(e=>{
                    console.log(e,'Leaves Error')
                })

        },
        applications({ commit, state }) {
            var user = JSON.parse(localStorage.getItem('user'));
            ////console.log(user)
            var y = moment().format('YYYY')
            var m = moment().format('MM')
            var mn = moment().add(1,"month").format('MM')
            axios
                .post(
                    state.api + "~/fs_get", {
                        table: "hr_application",
                        query: [
                            {
                                "f":"dated",
                                "o":">=",
                                "v":`${y}-${m}`
                            },
                            {
                                "f":"dated",
                                "o":"<=",
                                "v":`${y}-${mn}`
                            },
                            {
                                "f":"employee_id",
                                "o":"==",
                                "v":user.machine_id
                            }
                        ]
                    }).then(response => {
                    //console.log(response, 'Applications');
                    if (response.data.length) {
                        var s = response.data;
                        var t = []
                        for (var i in s) {
                            var f = s[i];
                            t.push(f)
                        }
                        var l = { forgot_to_mark: 0, special_circumstance: 0, meeting: 0, applications_list: [] }
                        //console.log(l, 'applications Number is');
                        //return
                        for (var i in t) {
                            //console.log(t[i].status)
                            if (t[i].status.hr != 'cancelled' && t[i].status.line_manager != 'cancelled') {
                                //console.log(t[i].type)
                                if (t[i].type == 'Forgot to Mark') {
                                    l.forgot_to_mark = parseInt(l.forgot_to_mark) + 1
                                } else if (t[i].type == 'Special Circumstance') {
                                    l.special_circumstance = parseInt(l.special_circumstance) + 1
                                } else if (t[i].type == 'Meeting') {
                                    l.meeting = parseInt(l.meeting) + 1
                                }
                            }
                        }
                        //console.log(l)
                        commit("applications_availed", l);
                    } else {
                        var l = state.leaves;
                        commit("leaves", l);
                    }
                    //commit("favorite");
                })

        },
        settings({ commit, state }) {
            var user = JSON.parse(localStorage.getItem('user'));
            // //console.log(user)
            axios.post(
                state.api + `/fs_get`,{
                    table:"hr_setting",
                    id:user.setting.id
                }).then(response => {
                    //console.log(response, 'Setting')
                    if (response.data) {
                        var s = response.data
                        var l = state.leaves;
                        l.sick_total = s.sick_leaves
                        l.casual_total = s.casual_leaves
                        l.annual_total = s.earned_leaved
                        commit("leaves", l);
                        var a = state.applications;
                        a.forgot_to_mark_total = s.ftm
                        a.special_circumstance_total = s.sc
                        a.meeting_total = s.meetings
                        commit("applications", a);
                    }
                })
        },
        approvals({ commit, state }) {
            var user = JSON.parse(localStorage.getItem('user'));
            if (user.role == 'HR') {
              var fil =   [
                    
                ]
              
            } else {
                var fil = [
                    {
                        "f":"line_manager.id",
                        "o":"==",
                        "v":user.id
                    }
                ]
            }

            axios
                .post(
                    state.api + "~/fs_get", 
                    {
                        table:'hr_employee',
                        query:fil
                    }
                ).then(response => {
                    if (response.data.length) {
                        var lm = []
                        var a = response.data
                        var emp = {}
                        for (var i in a) {
                            lm.push(a[i]._source.machine_id);
                            emp[a[i]._source.machine_id] = a[i]._source.name;
                        }
                        //console.log(emp)
                        if (user.role == 'HR') {
                            var fl = 
                            [
                                {
                                    "f":"employee_id",
                                    "o":"==",
                                    "v":lm
                                },
                                {
                                    "f":"status.hr",
                                    "o":"==",
                                    "v":"Pending"
                                }
                            ]
                        } else {
                            var fl = [
                                {
                                    "f":"employee_id",
                                    "o":"==",
                                    "v":lm
                                },
                                {
                                    "f":"status.line_manager",
                                    "o":"==",
                                    "v":"Pending"
                                }
                            ]
                        }
                        axios
                            .post(
                                state.api + "~/fs_get", {
                                table: "hr_leave",
                                query: fl
                            }
                            ).then(rs => {
                                var a = rs.data
                                var c = []
                                if (a.length) {
                                    for (var i in a) {
                                        var d = a[i];
                                        //console.log(d,emp)
                                        d.emp_name = emp[d.employee_id]
                                        c.push(d);
                                    }
                                    //console.log(c,'Emp Leaves')
                                    commit("approvals_leaves", c);
                                }

                            })
                        axios
                            .post(
                                state.api + "~/fs_get", {
                                table: "hr_application",
                                query:  fl
                            }
                            ).then(rs => {
                                var a = rs.data
                                var c = []
                                if (a.length) {
                                    for (var i in a) {
                                        var d = a[i];
                                        d.emp_name = emp[d.employee_id]
                                        c.push(d);
                                    }
                                    //console.log(c,'Emp Application')
                                    commit("approvals_applications", c);
                                }

                            })


                    }
                })
        }
    }
})